<template>
  <div class="row">
    <EmptyState
      v-if="showBlankState"
      :to="{ name: 'saved-filters-form' }"
      icon="jbsmd-pencil"
      :message="$gettext('You don\'t have any saved filters')"
      :customButtonText="$gettext('Create a filter')"
    />
    <div class="col-md-12" v-if="!showBlankState">
      <ContentHeader :title="$gettext('Saved filters')">
        <template #options>
          <router-link
            class="btn btn-primary align-self-baseline"
            to="saved-filters-form"
            ><i class="fas fa-plus mr-2"></i>
            <translate>Create a filter</translate></router-link
          >
        </template>
      </ContentHeader>

      <AdvancedTable
        store="savedFilters"
        :fields="fields"
        :advance-filter-enabled="false"
      >
        <template #cell(name)="data">
          <router-link
            :to="{
              name: 'saved-filters-form',
              params: { id: data.item.id }
            }"
            >{{ data.item.name }}</router-link
          >
        </template>
        <template #cell(created_at)="data"
          ><FormattedDate
            :date="data.item.created_at"
            :format="{ dateStyle: 'medium' }"
        /></template>
        <template #cell(actions)="data">
          <b-button
            @click="handleDelete(data.item)"
            variant="danger"
            class="btn btn-icon btn-sm ml-1"
            v-b-tooltip.hover
            :title="$gettext('Delete')"
            ><i class="fa fa-trash"></i
          ></b-button>
        </template>
      </AdvancedTable>
    </div>
  </div>
</template>
<script>
import FormattedDate from "@/components/FormattedDate.vue";
import { mapActions, mapGetters } from "vuex";
import AdvancedTable from "@/components/AdvancedTable.vue";
import EmptyState from "@/components/EmptyState.vue";
import ContentHeader from "@/components/ContentHeader.vue";
import Vue from "vue";
import { VBTooltip } from "bootstrap-vue";
Vue.directive("b-tooltip", VBTooltip);
export default {
  components: { ContentHeader, EmptyState, AdvancedTable, FormattedDate },

  created() {
    this.paginate();
  },
  data() {
    return {
      dataSourceToStore: {
        application: "applications",
        candidate: "candidates",
        "offer-application": "offerApplications",
        offer: "offers"
      },
      fields: ["name", "data_source", "is_default", "created_at", "actions"]
    };
  },
  computed: {
    ...mapGetters("savedFilters", ["items", "showBlankState"])
  },
  methods: {
    ...mapActions("savedFilters", ["paginate", "delete"]),
    handleDelete(item) {
      this.delete(item.id)
        .then(() => {
          return this.$store.commit(
            `${this.dataSourceToStore[item.data_source]}/clearFilters`
          );
        })
        .finally(() => (this.isPosting = false));
    }
  }
};
</script>
